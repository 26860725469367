import React from 'react'
import { HashRouter, BrowserRouter, Routes, Route } from 'react-router-dom'
import { blue } from '@mui/material/colors'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import R404 from './404'

import Picture from './Picture'

const App = () => {
    const theme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: blue[500],
            },
            secondary: {
                main: blue[900],
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                s: 375,
                sm: 675,
                md: 960,
                lg: 1560,
                xl: 1920,
            },
        },
        typography: { useNextVariants: true },
    })


    const isFileProto = window.location.protocol === 'file:'

    const routes = () => {
        return <>
            <Routes>
                <Route exact path='picture/:pictureID' element={<Picture />} />
                <Route path="*" element={<R404 />} />
            </Routes>
        </>
    }

    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {isFileProto && <HashRouter>
                        {routes()}
                    </HashRouter>}
                    { }
                    {!isFileProto && <BrowserRouter>
                        {routes()}
                    </BrowserRouter>}
                </LocalizationProvider>
            </StyledEngineProvider>
        </ThemeProvider>
    )
}

export default App
