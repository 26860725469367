import React from "react"
import { useMatch } from 'react-router-dom'

const Picture = () => {
    const match = useMatch('/picture/:pictureID')

    return <>
        <img alt="play" src={"https://api.studiocity.fun/pictures/" + match.params.pictureID}></img>
    </>
}

export default Picture